<template>
    <div class="CulteraMax">
        <!-- 星空背景 -->
        <div class="stars" ref="starsRef">
            <div v-for="index in 1000" :key="index" class="star">
            </div>
        </div>
        <!--头部导航 -->
        <div class="CulteraNavi">
            <!-- 点击返回首页 -->
            <div class="CulteraHomePage" @click="home()">
                <img src="../../assets/SpecialScenic/new/back.png" alt="" style="width:100%;height:100%;" />
            </div>
            <!-- 昵称介绍 -->
            <div class="CulteraName">
                <span style="margin-left: -4%">数字文创馆</span>
            </div>
            <!-- 点击进入个人中心 -->
            <div class="CulteraPersonal" @click="personage()">
                <img src="../../assets/CulturalHomePage/personal.png" alt="" style="width: 100%; height: 100%" />
            </div>
        </div>
        <!-- 内容 -->
        <div class="ConBox">
            <div class="ImgBox">
                <imgList :imgs="ConData.imgs"></imgList>
                <!-- <div class="Author">
                    <img src="../../assets/CulturalHomePage/avatar.png">
                    <div>创作者：{{ ConData.creator }}</div>
                    <div class="Btn">
                        <span @click="$refs.childRef.ChuFa()">去他的空间</span>
                        <span @click="$refs.childRef.ChuFa()">联系创作者</span>
                    </div>
                </div> -->
                <div style="width: 65%;margin: 3vh auto;border: 1px dashed white;"></div>
                <div style="color: white;width: 65%;margin: 0 auto;font-size: 2.8vh;">文创视频</div>
                <div v-for="(videos,index) in ConData.videos" :key="index" class="VideoBox">
                    <div class="Video">
                        <video controls="controls" @play="HandleFullScreenOne" @ended="HandleExitFullScreenOne" ref="myVideo" :src="videos.resourceUrl" style="width:100%;height: 100%;border-radius: 5px;"></video>
                    </div>
                    <div class="videotitle">{{ videos.title }}标题标题</div>
                </div>

                <div style="width: 65%;color: rgb(182, 182, 182);text-align: center;margin: 0 auto;">没有更多视频了哦~~</div>
            </div>
            <div class="TextBox">
                <div style="display: flex;justify-content: space-between;width: 100%;align-items: center;background-color: rgba(255,255,255,0.2);padding: 0.5vh 1vh;border-radius: 5px;">
                    <!-- 标题 -->
                    <div class="Title">{{ConData.title}}</div>
                    <!-- 作者 -->
                    <div style="display: flex;justify-content: space-between;align-items: center;">
                        <!-- 头像 -->
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <img src="../../assets/CulturalHomePage/avatar.png" style="width: 7vh;height: 7vh;border-radius: 50%;overflow: hidden;border: 1px solid rgb(95, 95, 95);">
                            <div style="text-indent: 0.5rem;">
                                创作者：{{ ConData.creator }}
                            </div>
                            <span style="display: inline-block;
                                  font-size: 1.5vh;
                                  margin-left: 2vh;
                                  padding: 0.2vh 1vh;
                                  border-radius: 6px;
                                  background: #67727c;
                                  cursor: pointer;
                                  text-align: center;
                                  text-indent: 0;" @click="$refs.childRef.ChuFa()">去他的空间</span>
                            <span style="display: inline-block;
                                  font-size: 1.5vh;
                                  margin-left: 2vh;
                                  padding: 0.2vh 1vh;
                                  border-radius: 6px;
                                  background: #67727c;
                                  cursor: pointer;
                                  text-align: center;
                                  text-indent: 0;" @click="$refs.childRef.ChuFa()">联系创作者</span>
                        </div>
                    </div>
                </div>

                <!-- 简介 -->
                <div class="Content" v-html="ConData.content"></div>
            </div>
            <!-- <div class="VideoBox">
                <VideoBox :VideoList="ConData.videos" :showInfo="false"></VideoBox>
            </div> -->
        </div>
        <TiShiBox ref="childRef"></TiShiBox>
        <Bottom_BarOne></Bottom_BarOne>
    </div>
</template>
    
<script>
import imgList from './components/imgList.vue'
import VideoBox from '../Scenicspace/components/VideoBox.vue'
import TiShiBox from '../Public/TiShiBox.vue'
import Bottom_BarOne from '../../components/Public/Bottom_BarOne';

export default {
    components: { imgList, VideoBox, TiShiBox, Bottom_BarOne },
    data () {
        return {
            distance: 600,//间距
            ConData: {
                imgs: []
            }
        };
    },

    created () {

    },
    mounted () {
        this.XingKo()
        this.getDetail()
        document.addEventListener('fullscreenchange', this.handleFullscreenChange);
        document.addEventListener('mozfullscreenchange', this.handleFullscreenChange);
        document.addEventListener('webkitfullscreenchange', this.handleFullscreenChange);
    },

    methods: {
        // 退出全屏暂停播放
        handleFullscreenChange () {
            const video = this.$refs.myVideo;
            if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {
                video.pause(); // 当视频退出全屏时暂停播放
            }
        },
        //点击视频全屏播放
        HandleFullScreenOne (e) {
            this.launchFullscreen(e.target);
        },
        // 进入全屏
        launchFullscreen (element) {
            //此方法不可以在異步任務中執行，否則火狐無法全屏
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
            } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen();
            } else if (element.oRequestFullscreen) {
                element.oRequestFullscreen();
            } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullScreen();
            } else {
                var docHtml = document.documentElement;
                var docBody = document.body;
                var videobox = element;
                var cssText = "width:100%;height:100%;overflow:hidden;";
                docHtml.style.cssText = cssText;
                docBody.style.cssText = cssText;
                videobox.style.cssText = cssText + ";" + "margin:0px;padding:0px;";
                document.IsFullScreen = true;
            }
        },
        HandleExitFullScreenOne (e) {
            this.exitFullscreen(e.target);
        },
        exitFullscreen (element) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.oRequestFullscreen) {
                document.oCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else {
                var docHtml = document.documentElement;
                var docBody = document.body;
                var videobox = element;
                docHtml.style.cssText = "";
                docBody.style.cssText = "";
                videobox.style.cssText = "";
                document.IsFullScreen = false;
            }
        },
        //  点击跳转首页
        home () {
            // this.$router.push('/')
            window.history.go(-1);
        },
        // 点击跳转个人中心
        personage () {
            // 进入页面就判断是否有token  如果没有那就继续登陆 如果有就进入个人中心
            if (this.$cookies.get("token") == null) {
                this.$router.push('/login')
            } else {
                this.$router.push('/personalCenter')
            }
        },
        XingKo () {
            let starNodes = Array.from(this.$refs.starsRef.children);
            starNodes.forEach((item) => {
                let speed = 0.2 + Math.random() * 1;
                let thisDistance = this.distance + Math.random() * 300;
                item.style.transformOrigin = `0 0 ${thisDistance}px`;
                item.style.transform =
                    `
                translate3d(0,0,-${thisDistance}px)
                rotateY(${Math.random() * -360}deg)
                rotateX(${Math.random() * -20}deg)
                scale(${speed},${speed})`;
            });
        },
        // 获取详情
        getDetail () {
            let t = this;
            var axios = require('axios');
            var config = {
                method: 'get',
                url: t.$Schttp + '/vtp/app/cultural/detail/' + t.$route.query.id,
            };
            axios(config)
                .then(function (res) {
                    t.ConData = res.data.data
                })
                .catch(function (error) {
                });
        },

    },
    beforeUnmount () {
        // 销毁监听视频
        document.removeEventListener('fullscreenchange', this.handleFullscreenChange);
        document.removeEventListener('mozfullscreenchange', this.handleFullscreenChange);
        document.removeEventListener('webkitfullscreenchange', this.handleFullscreenChange);
    }
};
</script>
     
    
<style lang="less" scoped>
* {
    margin: 0;
    padding: 0;
}
@font-face {
    font-family: 'YouShe';
    src: url('../../ZiTi/YouShe.ttf');
}
.CulteraMax {
    position: absolute;
    width: 100%;
    min-height: 100%;
    background: radial-gradient(
        200% 100% at bottom center,
        #101929,
        #000000,
        #1b2947
    );
    background-attachment: fixed;
    overflow: hidden;
    margin: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
    flex-direction: column;

    @keyframes rotate {
        0% {
            transform: perspective(400px) rotateZ(20deg) rotateX(-40deg)
                rotateY(0);
        }
        100% {
            transform: perspective(400px) rotateZ(20deg) rotateX(-40deg)
                rotateY(-360deg);
        }
    }
    .stars {
        transform: perspective(500px);
        transform-style: preserve-3d;
        position: absolute;
        width: 100%;
        height: 100%;
        perspective-origin: 50% 100%;
        animation: rotate 90s infinite linear;
        top: 40%;
        z-index: 1;
    }
    .star {
        width: 2px;
        height: 2px;
        background: #f7f7b8;
        position: absolute;
        top: 0;
        left: 0;
        backface-visibility: hidden;
    }

    .CulteraNavi {
        width: 80%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 2;
        .CulteraHomePage {
            width: 7.5vh;
            height: 7vh;
            border-radius: 50%;
            cursor: pointer;
        }

        .CulteraName {
            width: 95%;
            padding: 2vh 0;
            margin: 1rem 0;
            background: url(../../assets/CulturalHomePage/top.png);
            background-size: 100% 100%;
            color: #ffffff;
            font-size: 4vh;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'YouShe';
        }

        .CulteraPersonal {
            width: 7.5vh;
            height: 7vh;
            border-radius: 50%;
            cursor: pointer;
        }
    }
    .Title {
        font-size: 3vh;
        color: #fff;
        z-index: 2;
        text-align: center;
        text-indent: 0;
        font-weight: 600;
    }

    .Content {
        margin-top: 1vh;
        font-size: 2vh;
    }
    /deep/ img {
        max-width: 100%;
        height: 100%;
    }
    .ConBox {
        font-size: 1rem;
        width: 100%;
        overflow-y: scroll;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        z-index: 2;
        position: relative;
        left: -5vh;
        .ImgBox {
            width: 30%;

            .VideoBox {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                .Video {
                    width: 65%;
                }
            }
        }
        .TextBox {
            width: 60%;
            color: #fff;
            line-height: 1.8rem;
            box-sizing: border-box;
            margin-top: 3rem;
        }
        .VideoBox {
            width: 90%;
            margin: 1rem auto 2rem;
            // border: yellow 1px solid;
            // height: 20rem;
            color: white;
        }
        .VideoBox:hover {
            color: #ffe600 !important;
        }
    }

    // 滚动条设置
    @remvw: 1920 /100vw;

    ::-webkit-scrollbar {
        width: 7 / @remvw;
        /*对垂直流动条有效*/
        height: 7 / @remvw;
        /*对水平流动条有效*/
    }

    /*定义外层滚动槽*/
    ::-webkit-scrollbar-track-piece {
        border-radius: 6 / @remvw;
    }

    /*定义内层 滑块颜色、内阴影及圆角*/
    ::-webkit-scrollbar-thumb {
        border-radius: 6 / @remvw;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #8b8b8b;
        opacity: 0.3;
    }

    /*定义两端按钮的样式*/
    ::-webkit-scrollbar-button {
        display: none;
    }

    /*定义边角样式*/
    ::-webkit-scrollbar-corner {
        display: none;
    }
}
</style>